import React from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';
import CustomLayout from '../layouts/custom';

const TopContainer = tw.div`px-4 py-8 md:px-8 md:py-16 border-b`;
const Title = tw.h1`text-3xl md:text-7xl text-black font-extrabold`;
const Subtitle = tw.p`mt-6 text-gray-600 text-base md:text-lg font-light`;
const BottomContainer = tw.div`grid md:grid-cols-2 gap-8 px-4 py-8 md:px-8 md:py-16`;
const BottomInnerContainer = tw.div`font-light`;
const Title2 = tw.h2`text-xl md:text-3xl text-gray-800`;
const BottomOption = tw.ul`mt-6 list-disc list-inside text-gray-600`;
const A = tw(Link)`text-purple-600 hover:underline`;

export default () => (
  <CustomLayout title="404">
    <TopContainer>
      <Title>页面不存在！</Title>
      <Subtitle>抱歉，您请求的页面被移除或更改了位置。</Subtitle>
    </TopContainer>
    <BottomContainer>
      <BottomInnerContainer>
        <Title2>接下来？</Title2>
        <Subtitle>请检查您请求的 URL 是否有误，或者返回上一页。</Subtitle>
        <Subtitle>或许您需要：</Subtitle>
        <BottomOption>
          <li>
            回到<A to="/">首页</A>？
          </li>
          <li>
            看一看我们<A to="/service/">能为您做什么</A>？
          </li>
          <li>
            了解我们的<A to="/about/code-of-conduct/">宗旨</A>？
          </li>
        </BottomOption>
      </BottomInnerContainer>
      <img alt="404" src="/disconnected.png" />
    </BottomContainer>
  </CustomLayout>
);
